<template>
  <div class="pt-3">
    <Loader v-if="performingRequest" />
    <div class="dashboard__container--body">
      <div class="dashboard__container--body--col">
        <div>
          <h4>Add Cert:</h4>
          <div class="mb-3">
            <label for="title">Title</label>
            <input type="text" v-model="newCert.title"></input>
          </div>
          <div class="mb-5">
            <label for="description">Description</label>
            <input type="text" v-model="newCert.description"></input>
          </div>
          <div class="mb-5">
            <label for="url">Link to get (optional)</label>
            <input type="text" v-model="newCert.url"></input>
          </div>
          <div class="mb-3">
            <button class="btn btn__primary" @click="addCert()">
              Add Cert
              <transition name="fade">
                <span class="ml-2" v-if="performingRequest">
                <i class="fa fa-spinner fa-spin"></i>
                </span>
              </transition>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="dashboard__container--body">
      <h4 class="mb-3">Certs:</h4>
      <vue-good-table
        :columns="columns"
        :rows="certs"
         styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          placeholder: 'Search this table',
        }"
        :pagination-options="{
          enabled: true,
          mode: 'records',
          perPage: 25,
        }"
        :sort-options="{
          enabled: true,
          initialSortBy: {field: 'title', type: 'asc'}
        }"
      >

      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'url'">
          <span v-if="props.row.url">
            <a :href="props.row.url" target="_blank">
              <i class="fas fa-external-link ml-2 mr-2"></i>
            </a>
          </span>
        </span>
        <span v-else-if="props.column.field == 'title'">
          <router-link :to="`/settings/certs/` + props.row.id">
            {{props.row.title}}
          </router-link>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
      </vue-good-table>
    </div>
  </div>
</template>


<script>
// import * as moment from 'moment'
import { mapState } from 'vuex'
import Loader from '@/components/Loader.vue'
import router from '@/router'
const fb = require('../../firebaseConfig.js')

export default {
  name: 'manageCerts',
  data: () => ({
    newCert: {},
    performingRequest: false,
    columns: [
      {
        label: 'Title',
        field: 'title',
        width: '100px',
        sortable: false
      },
      {
        label: 'Link',
        field: 'url',
        width: '60px',
        sortable: false
      },
      {
        label: 'Description',
        field: 'description',
        sortable: false
      },
    ],
  }),
  created () {
    this.$store.dispatch("getCerts")
    // this.$store.dispatch("updateEventJobNos")
    
  },
  computed: {
    ...mapState(['userProfile', 'currentUser', 'certs']),
  },
  components: {
    Loader,
  },
  methods: {
    // updateStatus(row) {
    //   fb.backgroundChecksCollection.doc(row.id).update(row)
    // },
    onRowClick(params) {
      let url = `/settings/certs/` + params.row.id
      console.log(url)
      router.push(url)
    },
    async addCert() {
      this.performingRequest = true
      await fb.certsCollection.add(this.newCert)
      .then(
        doc => {
          fb.certsCollection.doc(doc.id).update({
            id: doc.id
          })
        }
      )
      this.newCert = {}
      this.performingRequest = false
    }
  },
  beforeDestroy() {
    this.$store.dispatch("clearCerts")
  }
}
</script>